<template>
  <ul class="clebex-item-section">
    <li class="clebex-item-section-item full-right-underline">
      <div class="clebex-item-content-wrapper">
        <div class="clebex-section-input">
          <VeeForm
            v-slot="{ errors }"
            class="form tiny-input edit-form"
            novalidate
            ><Field
              name="title"
              as="input"
              rules="required"
              type="number"
              min="0"
              max="100"
              id="meetingTItle"
              v-model="newValue"
              @change="newValue && setData()"
            />
            <span class="error-message" v-if="errors.title">
              {{ errors.name }}
            </span>
          </VeeForm>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "AverageOccupancyPercentage",
  computed: {
    ...mapState("companyPreferences", ["preferences", "preferenceUpdateData"])
  },
  data() {
    return {
      newValue: null
    };
  },
  mounted() {
    this.newValue = this.preferenceUpdateData.values[0].value;
  },
  props: {
    preferenceId: {
      type: Number,
      required: true
    }
  },
  methods: {
    ...mapActions("companyPreferences", [
      "setPreferenceUpdateData",
      "setPreferences"
    ]),
    setData() {
      const newPreferenceUpdateData = {
        values: [
          {
            value: parseInt(this.newValue)
          }
        ]
      };
      if (this.preferenceUpdateData.values[0].id) {
        newPreferenceUpdateData.values[0].id = this.preferenceUpdateData.values[0].id;
      }
      this.setPreferenceUpdateData(newPreferenceUpdateData);
    }
  }
};
</script>
